import React, { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Flex,
  Text,
  useBoolean,
  Spinner,
  Button,
  Box,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';

import { GlobalLayout } from '~/layouts/GlobalLayout';
import SeoHead from '~/components/common/Head';
import { ErrorModal } from '~/components/common/ErrorModal';
import { usePageError, useI18nLn } from '~/hooks/store';
import { useUser } from '~/hooks/resources';
import { LoginProviders } from '~/components/login/LoginProviders';
import { parseReturnUrl } from '~/lib/parse';

export type Query = {
  return_url?: string;
};

const LoginPage: React.FC = () => {
  const router = useRouter();
  const { user, loading, token } = useUser();
  const { t } = useI18nLn();
  const [initialized, setInitialized] = useBoolean();
  const { page_error, reset } = usePageError();
  const { return_url } = router.query as Query;

  useEffect(() => {
    if (!loading) {
      if (user?.isAnonymous ?? true) {
        setInitialized.on();
      } else {
        const signup = async () => {
          if (router.query.return_url) {
            await router.replace(parseReturnUrl(`${router.query.return_url}`));
          } else {
            await router.replace('/e');
          }
        };
        signup();
      }
    }
  }, [user, loading, token]);

  let item = <></>;
  if (loading) {
    item = <LoginPageSkeleton />;
  } else if (!(user?.isAnonymous ?? true)) {
    item = <Spinner />;
  } else {
    item = (
      <>
        <Flex width="full" justifyContent="center" flexDir="column">
          <Box h={8} />
          <Text fontSize="xl" fontWeight="bold" mb={4} px="16px">
            {t('guide.login')}
          </Text>
          {initialized || (user?.isAnonymous ?? true) || token ? (
            <LoginProviders methods="login" />
          ) : (
            <Spinner />
          )}
          <Flex mt={4} px={2} w="full">
            <Button
              as={Link}
              variant="outline"
              w="full"
              href={`/signup/?return_url=${return_url}`}
            >
              {t('guide.link_signup')}
            </Button>
          </Flex>
          <Box h="80px" />
        </Flex>
        {page_error ? (
          <ErrorModal
            title={page_error.title}
            body={page_error.body}
            isOpen={page_error !== null}
            onClose={reset}
          />
        ) : null}
      </>
    );
  }

  return (
    <GlobalLayout>
      <SeoHead
        title="ログイン"
        description="ユーザーログインをして、Web ROLLを楽しみましょう!"
        url="/login"
      />
      {item}
    </GlobalLayout>
  );
};

const LoginPageSkeleton: React.FC = () => {
  return (
    <Flex w="full" alignItems="center" justifyContent="center">
      <Flex
        flex={1}
        flexDir="column"
        maxW="600px"
        w="full"
        justifyContent="center"
      >
        <Box h={20} />
        <Box h={8} />
        <SkeletonText noOfLines={1} w="full" />
        <Box h={4} />
        <Flex px={4} py={4} backgroundColor="disable">
          <SkeletonText noOfLines={1} w={40} />
        </Flex>
        <Box h={8} />
        {[...Array(2)].map((_, index) => {
          return (
            <Flex w="full" flexDir="column" key={index}>
              <SkeletonText noOfLines={1} w={80} />
              <Box h={8} />
              <SkeletonText noOfLines={1} w="full" />
              <Box h={8} />
            </Flex>
          );
        })}
        <Skeleton w="full" h={8} />
        <Box h={8} />
        <Flex px={4} py={4} backgroundColor="disable">
          <SkeletonText noOfLines={1} w={40} />
        </Flex>
        <Box h={2} />
        <Skeleton w="full" h={8} />
        <Box h={2} />
        <Skeleton w="full" h={8} />
        <Box h={2} />
        <Skeleton w="full" h={8} />
        <Box h={8} />
        <Skeleton w="full" h={8} />
        <Box h={8} />
        <Box h={20} />
      </Flex>
    </Flex>
  );
};

export default LoginPage;
